import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { useMediaQuery } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { PATHS } from 'common/constants';
import cookie from '../../assets/icons/cookie.svg';
import classes from './CookieBanner.module.scss';


const CookieBanner = () => {
  const { t } = useTranslation();

  const [ isVisible, setIsVisible ] = useState(false);
  const isMobile = useMediaQuery('(max-width: 674px)');

  useEffect(() => {
    const cookieConsent = JSON.parse(localStorage.getItem('cookieConsent'));
    if (!cookieConsent) {
      setIsVisible(true);
    }
  }, []);

  const handleAccept = () => {
    localStorage.setItem('cookieConsent', 'true');
    setIsVisible(false);
  };

  if (!isVisible) return null;

  const renderCookieMessage = () => {
    const message = t('CookieMessage');
    const parts = message.split('cookies');

    return (
      <>
        {parts[ 0 ]}
        <Link to={PATHS.cookiesPolicy} className={classes.link}>
          cookies
        </Link>
        {parts[ 1 ]}
      </>
    );
  };

  return (
    <div className={classes.wrapper}>
      <div className={classes.banner}>
        <div className={classes.info}>
          {!isMobile && <img src={cookie} alt="cookie" />}
          <p className={classes.text}>{renderCookieMessage()}</p>
        </div>
        <button className={classes.button} onClick={handleAccept}>
          {t('Accept')}
        </button>
      </div>
    </div>
  );
};

export default CookieBanner;
