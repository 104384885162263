import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { REQUEST_STATUSES } from 'common/constants';
import { addQueryCases } from 'common/helpers';
import Cookies from 'js-cookie';


const name = 'editStudent';

const ENDPOINTS = {
  profile: '/api/v1/profile/',
  skills: '/api/v1/career_center/skills/',
  info: '/api/v1/profile/update/',
  portfolio: '/api/v1/career_center/portfolio/',
};

const studentAuth = () => {
  const token = Cookies.get('access_token');
  if (!token) throw new Error('No token found');
  return { headers: { Authorization: `Bearer ${token}` } };
};

export const fetchAllStudentData = createAsyncThunk(
  `${name}/fetchAllStudentData`,
  async (_, { extra: api }) => {
    const [ editStudentResponse, editStudentInfoResponse ] = await Promise.all([
      api.get(ENDPOINTS.profile, studentAuth()), api.get(ENDPOINTS.info, studentAuth())
    ]);

    return {
      editStudent: editStudentResponse.data,
      editStudentInfo: editStudentInfoResponse.data,
    };
  }
);

export const getEditStudent = createAsyncThunk(
  `${name}/getEditStudent`,
  async (_, { extra: api }) => {
    const response = await api.get(ENDPOINTS.profile, studentAuth());
    return response.data;
  }
);

export const updateEditStudent = createAsyncThunk(
  `${name}/updateEditStudent`,
  async (updateFields, { extra: api }) => {
    const response = await api.patch(
      ENDPOINTS.profile,
      updateFields,
      studentAuth()
    );
    return response.data;
  }
);

export const getEditStudentSkills = createAsyncThunk(
  `${name}/getEditStudentSkills`,
  async (course_id, { extra: api }) => {
    const response = await api.get(`${ENDPOINTS.skills}?course_id=${course_id}`, studentAuth());
    return response.data.results;
  }
);

export const searchSkills = createAsyncThunk(
  `${name}/searchSkills`,
  async (text, { extra: api }) => {
    const response = await api.get(
      `${ENDPOINTS.skills}?search=${text}`,
      studentAuth()
    );
    return response.data.results;
  }
);

export const getEditStudentInfo = createAsyncThunk(
  `${name}/getEditStudentInfo`,
  async (_, { extra: api }) => {
    const response = await api.get(ENDPOINTS.info, studentAuth());
    return response.data;
  }
);

export const updateEditStudentInfo = createAsyncThunk(
  `${name}/updateEditStudentInfo`,
  async (data, { extra: api }) => {
    const response = await api.post(ENDPOINTS.info, data, studentAuth());
    return response.data;
  }
);

export const deleteEditStudentInfo = createAsyncThunk(
  `${name}/deleteEditStudentInfo`,
  async (data, { extra: api }) => {
    try {
      const response = await api.delete(ENDPOINTS.info, {
        data,
        ...studentAuth()
      });
      return response.data;
    } catch (error) {
      console.error('Error deleting student info:', error);
    }
  }
);

export const getEditStudentPortfolio = createAsyncThunk(
  `${name}/getEditStudentPortfolio`,
  async (_, { extra: api }) => {
    const response = await api.get(ENDPOINTS.portfolio, studentAuth());
    return response.data.results;
  }
);

export const searchPortfolio = createAsyncThunk(
  `${name}/searchPortfolio`,
  async (text, { extra: api }) => {
    const response = await api.get(
      `${ENDPOINTS.portfolio}?search=${text}`,
      studentAuth()
    );
    return response.data.results;
  }
);

const initialState = {
  [ name + 'Status' ]: REQUEST_STATUSES.NOT_REQUESTED,
  [ name + 'Data' ]: {},
  [ name + 'Error' ]: null,

  skills: [],
  selectedSkills: [],
  customSkills: [],
  searchSkills: [],
  skillsStatus: REQUEST_STATUSES.NOT_REQUESTED,
  skillsError: null,
  removedSkills: [],

  info: {
    student_portfolio: [],
    student_projects: [],
    skills: [],
    custom_skills: [],
  },
  infoStatus: REQUEST_STATUSES.NOT_REQUESTED,
  infoError: null,

  portfolio: [],
  customPortfolio: [],
  selectedPortfolio: [],
  searchPortfolio: [],
  removedPortfolio: [],
  portfolioStatus: REQUEST_STATUSES.NOT_REQUESTED,
  portfolioError: null,

  deleteInfo: {},
  deleteInfoStatus: REQUEST_STATUSES.NOT_REQUESTED,
};

const editStudentSlice = createSlice({
  name,
  initialState,
  reducers: {
    selectSkill(state, action) {
      const skillId = action.payload.id;

      const isSkillAlreadySelected = state.selectedSkills.some(
        (skill) => skill.id === skillId
      );

      if (!isSkillAlreadySelected) {
        state.selectedSkills.push({
          ...action.payload,
          isCustom: false,
          noClient: false,
        });
        state.skills = state.skills.filter((skill) => skill.id !== skillId);
        state.searchSkills = state.searchSkills.filter(
          (skill) => skill.id !== skillId
        );

        state.removedSkills = state.removedSkills.filter(
          (skill) => skill.id !== skillId
        );
      }
    },
    resetSelectedSkills(state) {
      state.selectedSkills = state.selectedSkills.filter(
        (skill) => skill.isCustom
      );
      state.removedSkills = [];
      state.customSkills = [];
    },
    addCustomSkill(state, action) {
      state.customSkills.push({ ...action.payload, noClient: false });
      state.selectedSkills.push({
        ...action.payload,
        isCustom: true,
        noClient: false,
      });
    },
    removeSelectedSkill(state, action) {
      const skillId = action.payload.id;

      state.selectedSkills = state.selectedSkills.filter(
        (skill) => skill.id !== skillId
      );

      state.customSkills = state.customSkills.filter(
        (skill) => skill.id !== skillId
      );

      const originalSkills = [
        ...(state.info?.skills || []), ...(state.info?.custom_skills || []),
      ];

      const isOriginalSkills = originalSkills.some(
        (skill) => skill.id === skillId
      );

      if (isOriginalSkills) {
        state.removedSkills.push(action.payload);
      }

      const wasCustomSkill = action.payload.isCustom;
      if (!wasCustomSkill) {
        state.skills.push(action.payload);
      }
    },

    // Portfolio
    selectPortfolio: (state, action) => {
      const portfolioId = action.payload.id;
      const isPortfolioAlreadySelected = state.selectedPortfolio.some(
        (portfolio) => portfolio.id === portfolioId
      );
      const isOriginalAlreadySelected = state.portfolio.some(
        (portfolio) => portfolio.id === portfolioId
      );

      const isAlreadyInCustom = state.customPortfolio.some(
        (portfolio) => portfolio.id === portfolioId
      );

      if (!isOriginalAlreadySelected && !isAlreadyInCustom) {
        state.customPortfolio.push({ ...action.payload, isCustom: true });
      } else {
        if (!isPortfolioAlreadySelected) {
          state.selectedPortfolio.push({
            ...action.payload,
            isDefault: true,
            noClient: false,
          });
          state.searchPortfolio = [];

          state.removedPortfolio = state.removedPortfolio.filter(
            (item) => item.id !== portfolioId
          );
        }
      }
    },
    removeSelectedPortfolio(state, action) {
      const portfolioId = action.payload.id;

      state.selectedPortfolio = state.selectedPortfolio.filter(
        (item) => item.id !== portfolioId
      );
      state.customPortfolio = state.customPortfolio.filter(
        (item) => item.id !== portfolioId
      );

      const isOriginalPortfolio =
        state.info?.student_portfolio.some((item) => item.id === portfolioId) ||
        state.info?.student_projects.some((item) => item.id === portfolioId);

      if (isOriginalPortfolio) {
        state.removedPortfolio.push(action.payload);
      }
      if (state.customSkills.some((skill) => skill.id === portfolioId)) return;
    },
    resetState(state) {
      Object.assign(state, initialState);
    },
  },
  extraReducers(builder) {
    addQueryCases(builder, deleteEditStudentInfo, {
      status: [ 'deleteInfoStatus' ],
      data: [ 'deleteInfo' ],
      options: { concat: false },
    });
    builder.addCase(fetchAllStudentData.fulfilled, (state, action) => {
      const { editStudent, editStudentInfo } = action.payload;
      const studentSkills = (editStudent.skills || []).map((item) => ({
        id: item.id,
        title: item.title,
        isCustom: false,
        noClient: true,
      }));
      const customSkills = (editStudentInfo.custom_skills || []).map((item) => ({
        id: item.id,
        title: item.title,
        isCustom: true,
        noClient: true,
      }));
      const combinedSkills = [
        ...studentSkills, ...customSkills.filter(
          (skill) => !studentSkills.some((existing) => existing.id === skill.id)
        ),
      ];
      state.selectedSkills = combinedSkills;
      state[ `${name}Data` ] = editStudent;
      state.info = editStudentInfo;
      state.infoStatus = REQUEST_STATUSES.SUCCEEDED;
      state[ `${name}Status` ] = REQUEST_STATUSES.SUCCESS;
      const studentProjects = (editStudentInfo.student_projects || []).map((item) => ({
        id: item.id,
        title: item.project_name,
        url: item.url,
        isPortfolio: false,
        noClient: true,
      }));

      const studentPortfolios = (editStudentInfo.student_portfolio || []).map((item) => ({
        id: item.id,
        title: item.portfolio,
        url: item.url,
        isPortfolio: true,
        noClient: true,
      }));

      state.selectedPortfolio = [ ...studentProjects, ...studentPortfolios ];
    });

    builder.addCase(updateEditStudent.fulfilled, (state, action) => {
      state[ name + 'Data' ] = {
        ...state[ name + 'Data' ],
        ...action.payload,
      };

      state[ name + 'Status' ] = REQUEST_STATUSES.SUCCESS;
    });

    builder.addCase(updateEditStudent.pending, (state) => {
      state[ name + 'Status' ] = REQUEST_STATUSES.LOADING;
    });

    builder.addCase(updateEditStudent.rejected, (state, action) => {
      state[ name + 'Status' ] = REQUEST_STATUSES.FAILED;
      state[ name + 'Error' ] = action.error.message;
    });
    // Skills
    builder.addCase(getEditStudentSkills.fulfilled, (state, action) => {
      const selectedSkillIds = state.selectedSkills.map((skill) => skill.id);

      state.skills = action.payload.filter(
        (skill) => !selectedSkillIds.includes(skill.id)
      );
      state.skillsStatus = REQUEST_STATUSES.SUCCEEDED;
    });
    builder.addCase(searchSkills.fulfilled, (state, action) => {
      const selectedSkillIds = state.selectedSkills.map((skill) => skill.id);

      state.searchSkills = action.payload.filter(
        (skill) => !selectedSkillIds.includes(skill.id)
      );
      state.skillsStatus = REQUEST_STATUSES.SUCCEEDED;
    });
    builder.addCase(updateEditStudentInfo.fulfilled, (state, action) => {
      const { skills, student_portfolio, student_projects, custom_skills } =
        action.payload;

      state.info.skills = skills || [];
      state.info.student_portfolio = student_portfolio || [];
      state.info.student_projects = student_projects || [];
      state.info.custom_skills = custom_skills || [];

      state.infoStatus = REQUEST_STATUSES.SUCCESS;
    });
    // Portfolio
    builder.addCase(getEditStudentPortfolio.fulfilled, (state, action) => {
      state.portfolio = action.payload.filter(
        (item) =>
          !item.selectedPortfolio?.some(
            (selectPortfolio) => selectPortfolio.id === item.id
          )
      );
      state.portfolioStatus = REQUEST_STATUSES.SUCCEEDED;
    });
    builder.addCase(searchPortfolio.fulfilled, (state, action) => {
      state.searchPortfolio = action.payload;
      state.portfolioStatus = REQUEST_STATUSES.SUCCEEDED;
    });
  },
});

export default editStudentSlice;

export const {
  selectSkill,
  addCustomSkill,
  removeSelectedSkill,
  resetSelectedSkills,
  selectPortfolio,
  removeSelectedPortfolio,
  resetState,
} = editStudentSlice.actions;

export const editStudentSelect = (state) => state?.[ name ];
