export const PATHS = {
  main: '/',
  vacancies: '/vacancies',
  vacancyResume: 'vacancies/:id',
  detailCourse: 'course/:slug',
  detailTechnology: '/technology/:slug',
  aboutUs: '/aboutUs',
  FAQ: '/faq/',
  advantages: '/advantages',
  mapSite: '/mapSite',
  news: '/news',
  newsDetails: '/news/:id',
  technology: '/technology',
  course: '/course',
  allcourses: '/allcourses',
  privacy: '/privacy-policy',
  afterCourses: '/after-courses',
  cookiesPolicy: '/cookies-policy',
  // Geeks PRO
  careerCenter: '/geeks-pro',
  signIn: '/sign-in',
  signUp: '/sign-up',
  passwordRecovery: '/password_recovery',
  editStudent: '/edit-student',
  direction: '/direction',
  students: '/students/:slug',
  student: '/student/:slug/:id',
  // Geeks junior
  geeksJunior: '/geeks-junior',
};


export const BASE_URL = process.env.REACT_APP_BASE_URL;

export const REQUEST_STATUSES = {
  NOT_REQUESTED: 'notRequested',
  REQUESTED: 'requested',
  SUCCEEDED: 'succeeded',
  FAILED: 'failed',
  LOADING: 'loading',
};

export const BITRIX = {
  test: 'https://geeks.bitrix24site.ru/crm_form_lafsj/',
  laptops: 'https://geeks.bitrix24site.ru/crm_form_hawxo/',
  trialLesson: 'https://geeks.bitrix24site.ru/crm_form_ebvu8/',
  course: 'https://geeks.bitrix24site.ru/crm_form_85mqv/',
  anchor: 'https://geeks.bitrix24site.ru/crm_form/',
  geeksPro: 'https://geeks.bitrix24site.ru/crm_form_7u5vk/'
};

export const externalRecourse = { geekstudio: 'https://www.geekstudio.kg/' };

export const emailsFooter = {
  forTraining: 'office@geeks.kg',
  forPartners: 'marketing@geeks.kg',
  forInternship: 'pro@geeks.kg'
};
