import axios from 'axios';
import Cookies from 'js-cookie';
import { BASE_URL } from 'common/constants';


export const requester = axios.create({ baseURL: BASE_URL });

const clearTokensAndRedirect = () => {
  Cookies.remove('access_token');
  Cookies.remove('refresh_token');
  window.location.href = '/sign-in';
};

const getLanguage = () => localStorage.getItem('lang') || 'ru';

requester.interceptors.request.use((config) => {
  config.headers[ 'Accept-Language' ] = getLanguage();

  const accessToken = Cookies.get('access_token');
  if (accessToken) {
    config.headers[ 'Authorization' ] = `Bearer ${accessToken}`;
  }
  return config;
});

requester.interceptors.response.use(
  (response) => response,
  async (error) => {
    const originalRequest = error.config;

    if (error.response?.status === 401 && !originalRequest._retry) {
      const errorData = error.response?.data;
      const errorCode = errorData?.code;

      if (
        errorCode === 'token_not_valid' &&
        errorData?.messages?.some((msg) => msg.token_class === 'AccessToken')
      ) {
        console.warn('Access token is invalid or expired:', errorData?.detail);

        const refreshToken = Cookies.get('refresh_token');
        if (refreshToken) {
          try {
            const { data } = await requester.post('/api/v1/auth/refresh/', { refresh: refreshToken });
            const newAccessToken = data.access;
            Cookies.set('access_token', newAccessToken, {
              expires: 7,
              sameSite: 'Strict',
            });
            originalRequest._retry = true;
            originalRequest.headers[ 'Authorization' ] = `Bearer ${newAccessToken}`;
            return requester(originalRequest);
          } catch (refreshError) {
            const refreshErrorCode = refreshError.response?.data?.code;
            if (refreshErrorCode === 'token_not_valid') {
              console.error('Refresh token is invalid or expired:', refreshError.response?.data?.detail);
              clearTokensAndRedirect();
            } else {
              console.error('Unexpected error during token refresh:', refreshError);
            }
          }
        } else {
          console.error('No refresh token available.');
          clearTokensAndRedirect();
        }
      } else {
        console.error('Unexpected error or different token issue:', errorData?.detail);
        clearTokensAndRedirect();
      }
    }

    return Promise.reject(error);
  }
);
